<template>
  <div id="root">
      <slot />
    <div id="overlay" v-show="loading || show" >
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem;"
        :style="{
          position: 'absolute',
          top: 'calc(50% - ' + (70  / 2) + 'px)',
          left: 'calc(50% - ' + (70  / 2) + 'px)',
        }"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import {  BSpinner  } from 'bootstrap-vue'
export default {
  components: {
      BSpinner
  }  ,
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    'show_overlay': {
      default: true,
      type: Boolean
    },
  },
  data() {
    return {
      loading: 0,
    };
  },
  methods: {
    start() {
      this.loading++;
      const el = this.$el.querySelector(":focus");
      if (el) {
        el.blur();
      }
    },
    done() {
      if (this.loading > 0) {
        this.loading--;
      }
    },
  },
};
</script>

<style scoped>
#root {
  position: relative;
  width: 100%;
  height: 100vh;
  
}

#overlay {
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  user-select: none;
  
}
</style>
